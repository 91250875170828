<template>
  <div class="head-container">
    <!-- 搜索 -->
<!--    看下直客级别是否能X掉-->
    <el-select v-model="query.custSource"
               @change="toQuery"
               clearable placeholder="客户来源" class="filter-item" style="width: 130px">
      <el-option v-for="item in custSourceOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <!--    看下直客级别是否能X掉-->
    <el-select
        v-model="query.linkId"
        clearable
        filterable
        placeholder="直客级别"
        class="filter-item"
        style="width: 130px"
        @change="custLevelChange"
    >
      <el-option
          v-for="(item,index) in custLevelList"
          :key="index"
          :label="item.levelName"
          :value="item.id"
      />
    </el-select>
    <el-select v-model="query.custStatus"
               @change="toQuery"
               clearable placeholder="客户状态" class="filter-item" style="width: 130px">
      <el-option v-for="item in cusStatusOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-select v-model="query.auditStatus"
               @change="toQuery"
               clearable placeholder="审核状态" class="filter-item" style="width: 130px">
      <el-option v-for="item in ibStatusOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-input v-model="query.basicInfo" clearable placeholder="客户姓名/ID/手机/邮箱" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.belongInfo" clearable placeholder="上级姓名/ID" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.managerInfo" clearable placeholder="客户经理姓名/ID" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.mt4MainAcc" clearable placeholder="交易账号" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <div class="filter-item">
      申请时间：
      <el-date-picker
        style="width: 150px;"
        v-model="query.startTime"
        type="date"
        placeholder="开始日期">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="query.endTime"
        type="date"
        placeholder="结束日期">
      </el-date-picker>
    </div>
    <div class="filter-item">
      审核通过时间：
      <el-date-picker
        style="width: 150px;"
        v-model="query.startAuditTime"
        type="date"
        placeholder="开始日期">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="query.endAuditTime"
        type="date"
        placeholder="结束日期">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-close" @click="clear">清空</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
import { mapGetters } from 'vuex'
import { getGuestList } from '@/api/cus/tCustInfo'
import { queryMerchantCommissionLevel } from '@/api/ib/tManager'
export default {
  components: { eForm },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      custLevelList: [],
    }
  },
  created(){
    this.getCustLeveList();
  },
  computed: {
    ...mapGetters([
      'cusStatusOptions',
      'custSourceOptions',
      'ibStatusOptions'
    ])
  },
  methods: {
    checkPermission,
    getCustLeveList(){
      getGuestList().then((res) => {
        this.custLevelList = res;
      })
    },
    custLevelChange(e){
      if(!e){
        this.$store.commit('setCusmgrCustLvl', '');
        this.$emit('custLvlClearHandle',e);
      }else {
        this.toQuery();
      }
    },
    toQuery() {
      this.$emit('toQuery');
    },
    clear(){
      this.$emit('clear')
    }
  }
}
</script>
