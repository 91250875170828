<template>
  <el-dialog
    :append-to-body="true"
    v-if="dialog"
    :visible.sync="dialog"
    title="客户转移" width="800px"
  >
    <div
      v-if="!isMasterAgent"
      style="margin: 10px;padding: 10px;border-bottom: inset;"
    >
      <span style="font-size: 20px;width:150px;">客户经理转移：</span>
      <el-select v-model="managerId"
        filterable
        remote
        reserve-keyword
        :remote-method="getManagerList"
        :loading="searchLoading"
        placeholder="请选择客户经理"
        style="width:150px;margin-right: 5px">
        <el-option
          v-for="item in managerlist"
          :key="item.id"
          :label="item.fullname+'(No:'+item.id+')'"
          :value="item.id">
        </el-option>
      </el-select>
      <el-button type="primary" @click="modifyIbMan(false)">保存</el-button>
    </div>
    <div
      style="margin: 10px;padding: 10px;border-bottom: inset;"
    >
      <span style="font-size: 20px;width:150px;">归属代理转移:</span>
      <el-select
        v-model="belongId"
        filterable
        remote
        reserve-keyword
        :remote-method="getIbList"
        :loading="searchLoading"
        placeholder="请选择代理"
        style="width:180px;margin: 0 5px;"
      >
        <el-option
          v-for="item in ibList"
          :key="item.id"
          :label="item.userName+'(代理号:'+item.id+')'"
          :value="item.id">
        </el-option>
      </el-select>
      <span style="font-size: 20px;width:150px;padding-left: 20px">直客级别:</span>
      <el-select
        v-model="linkId"
        placeholder="请选择"
        style="margin-right: 10px;"
      >
        <el-option
          v-for="(item,index) in custLevelList"
          :key="index"
          :label="item.levelName"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-if="isShowMerchantLevel"
        v-model="merchantLevel"
        clearable
        placeholder="选择代理级别"
        class="filter-item"
        style="width: 130px"
      >
        <el-option
          v-for="item in currentSubLevleAndRules"
          :key="item.levelId"
          :label="item.levelName"
          :value="item.levelId"
        />
      </el-select>
      <el-button type="primary" @click="modifyIbMan(true)">保存</el-button>
    </div>
    <div
      v-if="isShowMerchantLevel"
      class="rule-table">
      <div style="font-size: 20px;" class="table-title">返佣规则：</div>
      <!--头部-->
      <el-row
        class="first-row"
        v-for="(item,index) in ruleData.slice(0,1)"
        :key="index"
      >
        <el-col
          class="col-box  isfirst-row"
          :span="3"
        >账户类型</el-col>
        <el-col
          class="col-box isfirst-row"
          v-for="(headerItem,k) in item.symbolGroup"
          :key="k"
          :span="3"
        >{{getHeaderItem(headerItem)}}</el-col>
      </el-row>
      <el-row
        class="second-row"
        v-for="(item,index) in ruleData"
        :key="index"
      >
        <el-col
          class="col-box"
          :span="3"
        >{{item.mt4GroupNamme}}</el-col>
        <el-col
          class="col-box"
          v-for="(colItem,k) in item.symbolGroup"
          :key="k"
          :span="3"
        >{{getColItem(colItem,k)}}</el-col>
      </el-row>
      <div class="no-data" v-if="!ruleData.length">暂无数据</div>
    </div>
  </el-dialog>
</template>

<script>
import { initData } from '@/api/data'
import { alterBelongInfo, getGuestList, getManagerList } from '@/api/cus/tCustInfo.js'
import { queryLevleAndRules,querySubLevleAndRules } from '@/api/ib/tManager.js'
import { mapGetters } from 'vuex'
export default {
  props: {
    sup_this: {
      type: Object,
      default: null
    },
    rowData:{
      type: Object,
      default(){
        return {}
      }
    },
    isShowMerchantLevel:{
      type: Boolean,
      default: false,
    },
  },
  created(){
    // this.getRuleList(levelId)
  },
  data() {
    return {
      loading: false,
      dialog: false,
      userId: 1,
      belongId:1,
      managerId: '',
      searchLoading: false,
      managerlist:[],
      ibList:[],
      merchantLevel: '',
      // 代理类型切换,数据会切换
      ruleData: [],
      currentSubLevleAndRules:[],

      linkId: '',
      custLevelList: [],
    }
  },
  computed:{
    // 代理
    isMasterAgent(){
      return this.rowData.belongId ? true : false;
    }
  },
  watch:{
    dialog(newVal){
      if(newVal&&this.isShowMerchantLevel){
        // 初始化
        this.getRuleList(this.rowData.levelId);
      }
    },
    rowData:{
      handler(newValue,oldValue){
        if(newValue){

        }
      },
      deep: true
    },
    belongId(newVal){
      if(newVal&&this.isShowMerchantLevel){
        this.getSubLevelAndRule(newVal);
      }
      if(newVal){
        this.linkId = '';
        this.getCustLeveList(newVal);
      }
    },
    merchantLevel(newVal){
      if(newVal){
        for (let i = 0; i < this.currentSubLevleAndRules.length; i++) {
          if(this.currentSubLevleAndRules[i].levelId == newVal){
            this.ruleData = this.currentSubLevleAndRules[i].content;
            break;
          }
        }
      }
    },
  },
  methods: {
    getCustLeveList(custId){
      getGuestList({
        custId:custId
      }).then((res) => {
        this.custLevelList = res;
      })
    },
    cancel() {
      this.resetForm()
    },
    getManagerList(query){
      const url = 'crm/tManager'
      const params = { fullname:query,page: 0, size: 500}
      this.searchLoading = true
      initData(url, params).then(res => {
        this.managerlist = res.content
        setTimeout(() => {
          this.searchLoading = false
        }, this.time)
      }).catch(err => {
        this.searchLoading = false
      });
    },
    initGetManagerlist(managerId){
      getManagerList().then((res) => {
        this.managerId = managerId === 999 ? '' : managerId;
        this.managerlist = res.content;
      });
    },
    getIbList( query ){
      const url = 'crm/tCustInfo'
      const params = {  custTypeStr:'1,2',basicInfo:query, page: 0, size: 500}
      this.searchLoading = true
      initData(url, params).then(res => {
        this.ibList = res.content
        setTimeout(() => {
          this.searchLoading = false
        }, this.time)
      }).catch(err => {
        this.searchLoading = false
      })
    },
    modifyIbMan(isIb) {
      if(this.isShowMerchantLevel && !this.isMasterAgent){
        if(!this.merchantLevel){
          this.$message({
            type: 'warning',
            message: '请选择代理级别'
          })
        }
      }

      this.loading = true;
      let parmas = {
        custId : this.userId,
      };
      if (isIb) {
        parmas['merId'] = this.belongId;
        parmas['oprType'] = 1;
        if(!this.isShowMerchantLevel){
          parmas['oprType'] = 3;
        }
        parmas.levelId = this.merchantLevel;
        if(!this.linkId){
          this.$message({
            type: 'warning',
            message: '请选择直客级别'
          });
          return;
        }
        parmas.linkId = this.linkId;

      } else {
        parmas['managerId'] = this.managerId;
        parmas['oprType'] = 2;
      }

      alterBelongInfo(parmas).then(res => {

        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        // this.resetForm()
        this.loading = false;
        this.sup_this.init();
        this.dialog = false;
      }).catch(err => {
        this.loading = false
      })
    },
    resetForm() {
      this.loading = false,
      this.userId = 1,
      this.belongId = 1 ,
      this.managerId = 0,
      this.searchLoading = false,
      this.managerlist = [],
      this.ibList =[]
    },
    getHeaderItem(headerItem){
      return headerItem.name;
    },
    getColItem(colItem){
      return colItem.value == -1 ? '/' : colItem.value;
    },
    getRuleList(levelId){
      queryLevleAndRules({
        levelId: levelId
      }).then((res) => {
        this.ruleData = res;
      })
    },
    getSubLevelAndRule(custId){
      if(custId){
        querySubLevleAndRules({
          custId: custId
        }).then((res) => {
          this.currentSubLevleAndRules = res;
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.child_row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 0;
  }
.child_ol {
    border-radius: 4px;
  }
.label {
    font-size: 18px;
    padding: 10px 10px;
  }
  .rule-table {
    padding: 10px;
    .isFirst {
      color: transparent;
    }
    .table-title {
      margin-bottom: 10px;
    }
    .col-box {
      height: 36px;
      line-height: 36px;
      text-align: center;
      border: 1px solid #cccccc;
      border-top: none;
      border-right: none;
      &.isfirst-row {
        border-top: 1px solid #cccccc;
      }
      &:last-of-type {
        border-right: 1px solid #cccccc;
      }
    }
  }
  .no-data {
    text-align: center;
  }
</style>
