<template>
  <el-dialog
    :append-to-body="true"
    :visible="dialogShow"
    width="700px"
    :before-close="beforeCloseHandle"
  >
    <div class="upgrade-dialog">
      <div class="title">升级代理</div>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item prop="agencyType">
          <el-radio v-model="form.agencyType" label="master">升级为主代理</el-radio>
          <el-radio v-model="form.agencyType" label="sun">升级为子代理</el-radio>
        </el-form-item>
        <template
          v-if="form.agencyType == 'master'"
        >
          <el-form-item label="客户经理" prop="manage">
            <el-select
              v-model="form.manage"
              placeholder="选择上级客户经理"
              filterable
            >
              <el-option
                v-for="(item,index) in manageOption"
                :key="index"
                :label="item.userName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="主代理级别" prop="masterAgency">
            <el-select
              v-model="form.masterAgency"
              placeholder="选择代理级别"
            >
              <el-option
                v-for="(item,index) in masterAgencyOption"
                :key="index"
                :label="item.levelName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-else>
          <el-row>
            <el-col :span="9">
              <el-form-item prop="subAgency" label="子代理级别:">
                <el-select
                   v-model="form.subAgency"
                   filterable
                   remote
                   reserve-keyword
                   :remote-method="getSubAgencylist"
                   :loading="searchLoading"
                   placeholder="请输入上级代理号"
                >
                  <el-option
                    v-for="item in superAgencylist"
                    :key="item.id"
                    :label="item.userName +'(No:'+item.id+')'"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item
                class="level-form-item"
                prop="sunAgencyLevel">
                <el-select
                  v-model="form.sunAgencyLevel"
                  placeholder="选择代理级别"
                >
                  <el-option
                    v-for="(item,index) in sunAgencyLevelOption"
                    :key="index"
                    :label="item.levelName"
                    :value="item.levelId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <div
              v-if="ruleData.length"
              class="rule-table">
              <div style="font-size: 20px;" class="table-title">返佣规则：</div>
              <!--头部-->
              <el-row
                class="first-row"
                v-for="(item,index) in ruleData.slice(0,1)"
                :key="index"
              >
                <el-col
                  class="col-box  isfirst-row"
                  :span="3"
                >账户类型</el-col>
                <el-col
                  class="col-box isfirst-row"
                  v-for="(headerItem,k) in item.symbolGroup"
                  :key="k"
                  :span="3"
                >{{getHeaderItem(headerItem)}}</el-col>
              </el-row>
              <el-row
                class="second-row"
                v-for="(item,index) in ruleData"
                :key="index"
              >
                <el-col
                  class="col-box"
                  :span="3"
                >{{item.mt4GroupNamme}}</el-col>
                <el-col
                  class="col-box"
                  v-for="(colItem,k) in item.symbolGroup"
                  :key="k"
                  :span="3"
                >{{getColItem(colItem,k)}}</el-col>
              </el-row>
              <div class="no-data" v-if="!ruleData.length">暂无数据</div>
            </div>
          </el-form-item>
        </template>
        <el-form-item>
          <div class="btn-line">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button @click="sureHandle" type="primary">确认</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { initData } from '@/api/data'
import { queryLevleAndRules,querySubLevleAndRules } from '@/api/ib/tManager.js'
import { upgradeCustomers } from '@/api/ib/ibMerchantInfo.js'
import { mapGetters } from 'vuex';
export default {
  props: {
    dialogShow: {
      default: false
    },
    currentClientInfo: {
      type: Object,
    },
  },
  data() {
    return {
      manageOption:[],
      masterAgencyOption:[],
      sunAgencyLevelOption:[],
      superAgencylist:[],
      searchLoading: false,

      isSelectAgencyLevel: true, // 选择了代理级别

      form:{
        agencyType: 'master',
        manage: '',
        subAgency: '',// 升级为子代理的上级代理
      },
      rules:{
        agencyType:[
          {required: true, message: '请选择升级为主代理还是子代理', trigger: 'change'}
        ],
        manage:[
          {required: true, message: '请选择上级客户经理',trigger: 'change'}
        ],
        masterAgency:[
          {required: true, message: '选择代理级别', trigger: 'change'}
        ],
        subAgency:[
          {required: true, message: '选择上级代理号', trigger: 'change'}
        ],
        sunAgencyLevel:[
          {required: true, message: '请选择代理级别', trigger: 'change'}
        ],
      },
      /*ruleData: [
        {
          accountNamme: 'STD',
          symbolGroup:[
            {
              name: 'Forex',
              value: '5 $/lot'
            },
            {
              name: 'GOLD',
              value: '3 pips/lot'
            },
            {
              name: 'SUVKER',
              value: '2 pips/lot'
            },
            {
              name: 'Oil',
              value: '0.2 $/lot'
            },
            {
              name: 'INDEX',
              value: '1 $/lot'
            },
          ]
        },
        {
          accountNamme: 'PRO',
          symbolGroup:[
            {
              name: 'Forex',
              value: '5 $/lot'
            },
            {
              name: 'GOLD',
              value: '3 pips/lot'
            },
            {
              name: 'SUVKER',
              value: '2 pips/lot'
            },
            {
              name: 'Oil',
              value: '0.2 $/lot'
            },
            {
              name: 'INDEX',
              value: '1 $/lot'
            },
          ]
        },
        {
          accountNamme: 'ECN',
          symbolGroup:[
            {
              name: 'Forex',
              value: '5 $/lot'
            },
            {
              name: 'GOLD',
              value: '3 pips/lot'
            },
            {
              name: 'SUVKER',
              value: '2 pips/lot'
            },
            {
              name: 'Oil',
              value: '0.2 $/lot'
            },
            {
              name: 'INDEX',
              value: '1 $/lot'
            },
          ]
        },
      ],*/
      ruleData: []
    }
  },
  created(){
    this.getManagerList()
    this.getAgentList()
  },
  watch:{
    'form.subAgency'(newValue,oldValue){
      if(newValue&&this.dialogShow){
        this.getSubLevelAndRule(newValue);
      }
    },
    'form.sunAgencyLevel'(newValue,oldValue){
      if(newValue&&this.dialogShow){
        this.getRuleList(newValue);
      }
    },
    'form.agencyType'(newValue,oldValue){
      if(newValue){
        this.$refs.form.clearValidate();
      }
    },
    dialogShow(newValue,oldValue){
      if(newValue){

      }
    },
    currentClientInfo:{
      handler(newValue,oldValue){
        if(newValue && this.dialogShow){
          this.formInit()
        }
      },
      deep: true
    }
  },
  methods: {
    formInit(){
      this.form = {
        agencyType: 'master',
        manage: '',
        subAgency: '',
      };
      this.ruleData = [];
    },
    getManagerList(){
      const url = 'crm/tManager'
      const params = { page: 0, size: 500}
      initData(url, params).then(res => {
        this.manageOption = res.content
      }).catch(err => {
      });
    },
    getAgentList(){
      const url = '/crm/querySystemLevel'
      const params = { page: 0, size: 500}
      initData(url, params).then(res => {
        this.masterAgencyOption = res
      }).catch(err => {
      });
    },
    close(){
      this.$emit('close-dialog');
    },
    beforeCloseHandle(){
      this.close();
    },
    cancelHandle() {
      this.close()
    },
    sureHandle(){
      this.$refs.form.validate((valid) => {
        if(valid){
          if(this.form.agencyType === 'master'){
            upgradeCustomers({
              type: 1,
              custId: this.currentClientInfo.id,
              belongId: this.form.manage,
              levelId: this.form.masterAgency,
            }).then((res) => {
              this.$notify({
                message: '升级成功',
                type: 'success'
              });
              this.$parent.init();
              this.close()
            })
          }else {
            upgradeCustomers({
              type: 2,
              custId: this.currentClientInfo.id,
              belongId: this.form.subAgency,
              levelId: this.form.sunAgencyLevel,
            }).then((res) => {
              this.$notify({
                message: '升级成功',
                type: 'success'
              });
              this.$parent.init();
              this.close();
            })
          }
        }else {
          console.log('nopass-validate')
          return false;
        }
      })
    },
    getHeaderItem(headerItem){
      return headerItem.name;
    },
    getColItem(colItem){
      return colItem.value == -1 ? '/' : colItem.value;
    },
    //返佣规则
    getRuleList(levelId){
      queryLevleAndRules({
        levelId: levelId
      }).then((res) => {
        this.ruleData = res;
      })
    },
    getSubAgencylist( query ){
      const url = 'crm/tCustInfo'
      const params = {  custTypeStr:'1,2',basicInfo:query, page: 0, size: 500}
      this.searchLoading = true
      initData(url, params).then(res => {
        this.superAgencylist = res.content
        setTimeout(() => {
          this.searchLoading = false
        }, this.time)
      }).catch(err => {
        this.searchLoading = false
      })
    },
    getSubLevelAndRule(custId){
      if(custId){
        querySubLevleAndRules({
          custId: custId
        }).then((res) => {
          this.sunAgencyLevelOption = res;
        })
      }
    },
  }
}
</script>
<style lang="less">
  .upgrade-dialog {
    .level-form-item {
      .el-form-item__content {
        margin-left: 10px!important;
      }
    }
  }
</style>
<style lang="less" scoped>
  .upgrade-dialog {
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 20px;
    }
    .btn-line {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .rule-table {
    padding: 10px;
    .isFirst {
      /*color: transparent;*/
    }

    .table-title {
      margin-bottom: 10px;
    }
    .col-box {
      height: 36px;
      line-height: 36px;
      text-align: center;
      border: 1px solid #cccccc;
      border-top: none;
      border-right: none;
      &.isfirst-row {
        border-top: 1px solid #cccccc;
      }
      &:last-of-type {
        border-right: 1px solid #cccccc;
      }
    }
  }
  .no-data {
    text-align: center;
  }
</style>
