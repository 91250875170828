<template>
  <el-dialog
    :append-to-body="true"
    v-if="dialogShow"
    :visible="dialogShow"
    title="开户配置"
    width="500px"
    class="openAccountConfigDialog"
    :before-close="beforeCloseHandle"
  >
    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="60px"
        label-position="center"
    >
      <el-form-item
          v-if="stdGroupFlag"
          prop="stdGroup"
          label="STD"
      >
        <el-select
            v-model="form.stdGroup"
            placeholder="请选择"
            :clearable="clearableFlag"
            style="width: 100%"
        >
          <el-option
            v-for="(item,index) in stdInfo.groupDetail"
            :key="index"
            :label="item.desc"
            :value="item.groupDetailId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          v-if="proGroupFlag"
          prop="proGroup"
          label="PRO"
      >
        <el-select
            v-model="form.proGroup"
            :clearable="clearableFlag"
            placeholder="请选择"
            style="width: 100%"
        >
          <el-option
              v-for="(item,index) in proInfo.groupDetail"
              :key="index"
              :label="item.desc"
              :value="item.groupDetailId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          v-if="ecnGroupFlag"
          prop="ecnGroup"
          label="ECN"
      >
        <el-select
            v-model="form.ecnGroup"
            :clearable="clearableFlag"
            placeholder="请选择"
            style="width: 100%"
        >
          <el-option
              v-for="(item,index) in ecnInfo.groupDetail"
              :key="index"
              :label="item.desc"
              :value="item.groupDetailId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          v-if="centGroupFlag"
          prop="centGroup"
          label="Cent"
      >
        <el-select
            v-model="form.centGroup"
            :clearable="clearableFlag"
            placeholder="请选择"
            style="width: 100%"
        >
          <el-option
              v-for="(item,index) in centInfo.groupDetail"
              :key="index"
              :label="item.desc"
              :value="item.groupDetailId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          prop="defaultGroup"
          label="注册开户默认组"
          label-width="150px"
      >
        <el-radio-group
            v-model="form.defaultGroup"
        >
          <el-radio
              v-if="stdInfo.groupId && form.stdGroup"
              :label="1"
          >STD</el-radio>
          <el-radio
              v-if="proInfo.groupId && form.proGroup"
              :label="2"
          >PRO</el-radio>
          <el-radio
              v-if="ecnInfo.groupId && form.ecnGroup"
              :label="3"
          >ECN</el-radio>
          <el-radio
              v-if="centInfo.groupId && form.centGroup"
              :label="4"
          >Cent</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <div class="btn-line">
          <span></span>
          <el-button
              @click="sureHandle"
              type="primary"
          >确认</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { queryDefaultGroupDetail, queryGroupDetailAll, saveGroupDetail } from '@/api/ib/ibMerchantInfo.js'
import { mapGetters } from 'vuex';
export default {
  name: 'openAccountConfigDialog',
  props: {
    // 需要获取userId,拉取账户配置 todo
    propName: {
      type: Number,
      default: 0
    },
    dialogShow: {
      default: false
    },
    rowData: {
      type: Object,
      default(){
        return {
          custSource: '',
        }
      }
    }
  },
  data() {
    return {
      stdInfo:{
        groupId: 0,
        groupDetail:[],
      },
      proInfo:{
        groupId: 0,
        groupDetail:[],
      },
      ecnInfo:{
        groupId: 0,
        groupDetail:[],
      },
      centInfo: {
        groupId: 0,
        groupDetail:[],
      },

      form:{
        stdGroup:'',
        proGroup:'',
        ecnGroup:'',
        centGroup:'',
        defaultGroup: ''
      },
      // 代理直客所有项都是必填项 todo
      // 无上级代理的直客所有项都不是必填项 todo
      ruleData: []
    }
  },
  watch:{
    dialogShow(newValue,oldValue){
      this.$refs.form && this.$refs.form.resetFields();
      this.$refs.form && this.$refs.form.clearValidate();
      if(newValue){
        this.getGroups();
      }
    },
    rowData:{
      handler(newValue){
        this.getGroups();
      },
      deep: true
    },
  },
  computed: {
    uperIsAgent(){
      if(this.rowData.belongId){
        return true;
      }else {
        return false;
      }
    },
    stdGroupFlag(){
      if(this.uperIsAgent){
        return this.form.stdGroup;
      }else {
        return this.stdInfo.groupId;
      }
    },
    proGroupFlag(){
      if(this.uperIsAgent){
        return this.form.proGroup;
      }else {
        return this.proInfo.groupId;
      }
    },
    ecnGroupFlag(){
      if(this.uperIsAgent){
        return this.form.ecnGroup;
      }else {
        return this.ecnInfo.groupId;
      }
    },
    centGroupFlag(){
      if(this.uperIsAgent){
        return this.form.centGroup;
      }else {
        return this.centInfo.groupId;
      }
    },

    clearableFlag(){
      if(this.uperIsAgent){
        return false;
      }else {
        return true;
      }
    },
    rules() {
      if(this.rowData.belongId){
        return {
          stdGroup: [
            {required:true, message: '请选择',trigger: 'change'}
          ],
          proGroup: [
            {required:true, message: '请选择',trigger: 'change'}
          ],
          ecnGroup: [
            {required:true, message: '请选择',trigger: 'change'}
          ],
          centGroup: [
            {required:true, message: '请选择',trigger: 'change'}
          ],
          defaultGroup: [
            {required:true, message: '请选择',trigger: 'change'}
          ],
        }
      }else {
        //至少选择一项 todo
        return {
          defaultGroup: [
            {required:true, message: '请选择',trigger: 'change'}
          ],
        }
      }
    }
  },
  methods: {
    resetForm(){
      this.stdInfo = {
        groupId: 0,
        groupDetail:[],
      };
      this.proInfo = {
        groupId: 0,
        groupDetail:[],
      };
      this.ecnInfo = {
        groupId: 0,
        groupDetail:[],
      };
      this.centInfo = {
        groupId: 0,
        groupDetail:[],
      }
      this.form = {
        stdGroup:'',
        proGroup:'',
        ecnGroup:'',
        centGroup:'',
        defaultGroup: ''
      }
    },
    getGroups(){
      // 上级支持的大组 todo custId
      let params = {

      }
      if(this.rowData.custSource == 1){
        params.custId = this.rowData.belongId;
      }
      queryGroupDetailAll(params).then((res) => {
        for (let i = 0; i <res.content.length ; i++) {
          if(res.content[i].groupName == 'STD'){
            this.stdInfo = res.content[i];
          }
          if(res.content[i].groupName == 'PRO'){
            this.proInfo = res.content[i];
          }
          if(res.content[i].groupName == 'ECN'){
            this.ecnInfo = res.content[i];
          }
          // 验证 todo
          if(res.content[i].groupName == 'Cent'){
            this.centInfo = res.content[i];
          }
        }
        this.querySetting();
      })
    },
    querySetting(){
      queryDefaultGroupDetail({
        custId: this.rowData.id,
      }).then((res) => {
        if(res.defaultGroupId){
          this.form.defaultGroup = res.defaultGroupId;
        }
        for (let i = 0; i < res.custGroups.length; i++) {
          if(res.custGroups[i].groupId == this.stdInfo.groupId){
            this.form.stdGroup = res.custGroups[i].groupDetailId + '';
          }
          if(res.custGroups[i].groupId == this.proInfo.groupId){
            this.form.proGroup = res.custGroups[i].groupDetailId + '';
          }
          if(res.custGroups[i].groupId == this.ecnInfo.groupId){
            this.form.ecnGroup = res.custGroups[i].groupDetailId + '';
          }
          if(res.custGroups[i].groupId == this.centInfo.groupId){
            this.form.centGroup = res.custGroups[i].groupDetailId + '';
          }
        }
      })
    },
    close(){
      this.$refs.form && this.$refs.form.clearValidate();
      this.$emit('close-dialog');
    },
    beforeCloseHandle(){
      this.close();
    },
    sureHandle(){
      this.$refs.form.validate((valid) => {
        if(valid){

          if(!this.uperIsAgent){
            //无上级代理的直客 所有大组非必选,但至少有一个值
            if(!this.form.stdGroup && !this.form.proGroup && !this.form.ecnGroup && !this.form.centGroup){
              this.$message({
                type: 'error',
                message: '请至少选择一项分组'
              })
              return
            }
          }
          let params = {
            custId: this.rowData.id,
            defaultGroupId: this.form.defaultGroup,
          }

          let custGroups = [];
          if(this.stdInfo.groupId){
            custGroups.push({
              groupId: this.stdInfo.groupId,
              groupDetailId: this.form.stdGroup,
            })
          }
          if(this.proInfo.groupId){
            custGroups.push({
              groupId: this.proInfo.groupId,
              groupDetailId: this.form.proGroup,
            })
          }
          if(this.ecnInfo.groupId){
            custGroups.push({
              groupId: this.ecnInfo.groupId,
              groupDetailId: this.form.ecnGroup,
            })
          }
          // 加上美分组 todo
          if(this.centInfo.groupId){
            custGroups.push({
              groupId: this.centInfo.groupId,
              groupDetailId: this.form.centGroup,
            })
          }

          params.custGroups = custGroups;
          saveGroupDetail(params).then((res) => {
            this.$notify({
              message: '修改成功',
              type: 'success'
            });
            this.$emit('success-save')
          })


        }else {
          console.log('nopass-validate')
          return false;
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .openAccountConfigDialog {
    .btn-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>
