import { render, staticRenderFns } from "./openAccountConfigDialog.vue?vue&type=template&id=42775158&scoped=true&"
import script from "./openAccountConfigDialog.vue?vue&type=script&lang=js&"
export * from "./openAccountConfigDialog.vue?vue&type=script&lang=js&"
import style0 from "./openAccountConfigDialog.vue?vue&type=style&index=0&id=42775158&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42775158",
  null
  
)

export default component.exports