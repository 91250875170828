<template>
  <div>
    <span @click="to">转移</span>
    <eForm ref="form"
           :sup_this="sup_this"
           :is-add="false"
           :rowData="data"
           :isShowMerchantLevel="isShowMerchantLevel"
    />
  </div>
</template>
<script>
import eForm from './transferform'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
    isShowMerchantLevel:{
      type: Boolean,
      default: false,
    },
  },
  methods: {
    to() {
      const _this = this.$refs.form;
      _this.userId = this.data.id;
      _this.belongId = this.data.belongId;
      _this.linkId = this.data.linkId;
      // _this.managerId = this.data.managerId,
        _this.initGetManagerlist(this.data.managerId);
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
