<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="500px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="80px">
      <el-form-item label="所属板块编码">
        <el-input v-model="form.sectorCode" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="所属对象id">
        <el-input v-model="form.belongId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="客户类型  1:个人代理  2:企业代理  3:直客">
        <el-input v-model="form.custType" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="客户来源, 1:代理商  2:直客  3:直客推荐  4:平/越级推荐">
        <el-input v-model="form.custSource" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="客户级别：1.lev.A  2.Lev.B  3.Lev.C  99.Special">
        <el-input v-model="form.custLvl" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="客户特殊级别描述">
        <el-input v-model="form.custLvlComment" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="客户状态  1:注册未开户  2:开户未入金  3:入金未交易  4:已交易  5:销户">
        <el-input v-model="form.custStatus" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="审核状态    1:待客服审核  2:待交易部审核  3:审核拒绝  4:审核通过  5:冻结  6:销户">
        <el-input v-model="form.auditStatus" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="申请时间">
        <el-input v-model="form.applyDate" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="MT4主账号">
        <el-input v-model="form.mt4MainAcc" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="名字">
        <el-input v-model="form.firstName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="中间名">
        <el-input v-model="form.middlName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="姓氏">
        <el-input v-model="form.lastName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="用户名/昵称">
        <el-input v-model="form.userName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="form.password" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="性别, 1：女；2：男">
        <el-input v-model="form.gender" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="证件类型, 默认为1：身份证，详见代码表">
        <el-input v-model="form.certType" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="证件号码">
        <el-input v-model="form.certId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="证件正面图片, 影像系统文件名">
        <el-input v-model="form.certValidatedImgFront" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="证件反面图片, 影像系统文件名">
        <el-input v-model="form.certValidatedImgBack" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="邮箱地址">
        <el-input v-model="form.email" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input v-model="form.phone" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="生日 06/10/1985">
        <el-input v-model="form.birthday" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="固定电话">
        <el-input v-model="form.telephone" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="IM账号">
        <el-input v-model="form.imAcc" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="网址">
        <el-input v-model="form.website" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="国籍编码">
        <el-input v-model="form.nationality" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="家庭住址国家">
        <el-input v-model="form.homeCountryCode" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="家庭住址省">
        <el-input v-model="form.homeProvince" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="家庭住址市">
        <el-input v-model="form.homeCity" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="家庭住址区">
        <el-input v-model="form.homeStreet" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="家庭地址详细地址">
        <el-input v-model="form.homeAddress" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="家庭地址邮编">
        <el-input v-model="form.homePostcode" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="工作地址国家">
        <el-input v-model="form.workCountryCode" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="工作地址省">
        <el-input v-model="form.workProvince" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="工作地址市">
        <el-input v-model="form.workCity" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="工作地址区">
        <el-input v-model="form.workStreet" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="工作地址详细地址">
        <el-input v-model="form.workAddress" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="工作地址邮编">
        <el-input v-model="form.workPostcode" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="地址证明照片">
        <el-input v-model="form.addressImg" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="雇主名称">
        <el-input v-model="form.bossFullname" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="职位">
        <el-input v-model="form.title" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="行业">
        <el-input v-model="form.industry" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="交易经验">
        <el-input v-model="form.tradingAges" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="历史投资年限类型">
        <el-input v-model="form.investExperienceYear" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="从业经验">
        <el-input v-model="form.agentAges" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="年均收入类型">
        <el-input v-model="form.annualIncome" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="资金来源类型">
        <el-input v-model="form.fundsSource" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="是否有客户  1:YES  2:NO">
        <el-input v-model="form.havingClients" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="来源渠道 1.EMPLOYEE 2.WEBSITE 3.BAIDU/GOOGLE 4.Wechat/Facebook 5.Linkedin 6.Other">
        <el-input v-model="form.source" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="其他来源渠道">
        <el-input v-model="form.otherSource" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行网点省">
        <el-input v-model="form.bankOutletProvince" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行网点市">
        <el-input v-model="form.bankOutletCity" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行网点">
        <el-input v-model="form.bankOutlet" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行名称">
        <el-input v-model="form.bankName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行卡号">
        <el-input v-model="form.bankAccount" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行卡swiftCode">
        <el-input v-model="form.swiftCode" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行卡正面照片">
        <el-input v-model="form.bankFrontImg" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="银行卡反面照片">
        <el-input v-model="form.bankBackImg" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="机构前台截图">
        <el-input v-model="form.oldOrgImg" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="资金量截图">
        <el-input v-model="form.oldFundImg" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="出入金截图">
        <el-input v-model="form.oldDepositImg" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="客户量截图">
        <el-input v-model="form.oldClientImg" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="交易量截图">
        <el-input v-model="form.oldTradeImg" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="代理区域">
        <el-input v-model="form.developArea" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="子代理链接">
        <el-input v-model="form.linkForChild" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="子代理链接二维码文件名">
        <el-input v-model="form.qrForChild" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="客户注册链接">
        <el-input v-model="form.linkForCust" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="客户注册链接二维码文件名">
        <el-input v-model="form.qrForCust" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-input v-model="form.createTime" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="更新时间">
        <el-input v-model="form.updateTime" style="width: 370px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/cus/tCustInfo'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false, dialog: false,rules:{},
      form: {
        id: '',
        sectorCode: '',
        belongId: '',
        custType: '',
        custSource: '',
        custLvl: '',
        custLvlComment: '',
        custStatus: '',
        auditStatus: '',
        applyDate: '',
        mt4MainAcc: '',
        firstName: '',
        middlName: '',
        lastName: '',
        userName: '',
        password: '',
        gender: '',
        certType: '',
        certId: '',
        certValidatedImgFront: '',
        certValidatedImgBack: '',
        email: '',
        phone: '',
        birthday: '',
        telephone: '',
        imAcc: '',
        website: '',
        nationality: '',
        homeCountryCode: '',
        homeProvince: '',
        homeCity: '',
        homeStreet: '',
        homeAddress: '',
        homePostcode: '',
        workCountryCode: '',
        workProvince: '',
        workCity: '',
        workStreet: '',
        workAddress: '',
        workPostcode: '',
        addressImg: '',
        bossFullname: '',
        title: '',
        industry: '',
        tradingAges: '',
        investExperienceYear: '',
        agentAges: '',
        annualIncome: '',
        fundsSource: '',
        havingClients: '',
        source: '',
        otherSource: '',
        bankOutletProvince: '',
        bankOutletCity: '',
        bankOutlet: '',
        bankName: '',
        bankAccount: '',
        swiftCode: '',
        bankFrontImg: '',
        bankBackImg: '',
        oldOrgImg: '',
        oldFundImg: '',
        oldDepositImg: '',
        oldClientImg: '',
        oldTradeImg: '',
        developArea: '',
        linkForChild: '',
        qrForChild: '',
        linkForCust: '',
        qrForCust: '',
        remark: '',
        createTime: '',
        updateTime: ''
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.loading = true
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        sectorCode: '',
        belongId: '',
        custType: '',
        custSource: '',
        custLvl: '',
        custLvlComment: '',
        custStatus: '',
        auditStatus: '',
        applyDate: '',
        mt4MainAcc: '',
        firstName: '',
        middlName: '',
        lastName: '',
        userName: '',
        password: '',
        gender: '',
        certType: '',
        certId: '',
        certValidatedImgFront: '',
        certValidatedImgBack: '',
        email: '',
        phone: '',
        birthday: '',
        telephone: '',
        imAcc: '',
        website: '',
        nationality: '',
        homeCountryCode: '',
        homeProvince: '',
        homeCity: '',
        homeStreet: '',
        homeAddress: '',
        homePostcode: '',
        workCountryCode: '',
        workProvince: '',
        workCity: '',
        workStreet: '',
        workAddress: '',
        workPostcode: '',
        addressImg: '',
        bossFullname: '',
        title: '',
        industry: '',
        tradingAges: '',
        investExperienceYear: '',
        agentAges: '',
        annualIncome: '',
        fundsSource: '',
        havingClients: '',
        source: '',
        otherSource: '',
        bankOutletProvince: '',
        bankOutletCity: '',
        bankOutlet: '',
        bankName: '',
        bankAccount: '',
        swiftCode: '',
        bankFrontImg: '',
        bankBackImg: '',
        oldOrgImg: '',
        oldFundImg: '',
        oldDepositImg: '',
        oldClientImg: '',
        oldTradeImg: '',
        developArea: '',
        linkForChild: '',
        qrForChild: '',
        linkForCust: '',
        qrForCust: '',
        remark: '',
        createTime: '',
        updateTime: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
