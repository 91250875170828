<template>
  <div class="app-container">
    <eHeader
            :query="query"
            @toQuery="toQuery"
            @clear="clear"
            @custLvlClearHandle="custLvlClearHandle"
            ref="eheader"
    />
<!--    直接把传query,子组件v-model绑定的方式修改了,才能把问题解决 todo -->
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" stripe size="small" style="width: 100%;">
      <el-table-column prop="id" label="ID" width="80"/>
      <el-table-column label="客户信息" width="200px">
        <template slot-scope="scope">
            <div v-if="scope.row.userName!=null">{{scope.row.userName}}</div>
            <div v-if="scope.row.phone!=null"><br>{{scope.row.phone}}</div>
            <div v-if="scope.row.phone!=null"><br>{{scope.row.email}}</div>
        </template>
      </el-table-column>
      <el-table-column
          label="客户级别"
      >
        <template slot-scope="scope">
          <span>{{getCustomerLevel(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="custSource" label="客户经理">
        <template slot-scope="scope">
          <span>{{ scope.row.managerName }}</span><br>
          <span>{{ parseCustSource(scope.row.managerId)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="custSource" label="客户来源">
        <template slot-scope="scope">
          <span>{{ parseCustSource(scope.row.custSource)}}</span>
        </template>
      </el-table-column>
      <el-table-column  label="上级信息" width="200px">
         <template slot-scope="scope">
           <div v-if="scope.row.belongId!=null"><br>{{scope.row.belongName}}</div>
           <div v-if="scope.row.belongId!=null">{{scope.row.belongId}}</div>
           <div v-if="scope.row.belongId==null">无</div>
        </template>
      </el-table-column>
      <el-table-column prop="auditStatus" label="客户状态">
        <template slot-scope="scope">
          <span>{{ parseIbStatus(scope.row.auditStatus)}}</span>
          <span><br>{{ parseCusStatus(scope.row.custStatus)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mt4MainAcc" label="交易账户"/>
      <el-table-column prop="applyDate" label="申请时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.applyDate) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="审核通过时间">
        <template slot-scope="scope">
          <span>{{ getAuditTime(scope.row.auditTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="360px" align="center">
        <template slot-scope="scope">
          <el-button
                  v-if="checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_SELECT'])"
                  size="mini"
                  type="success"
                  @click="to(1,scope.row)"
          >查看</el-button>
          <el-button
                  v-if="checkPermission(['ADMIN','CUST_SERVER_ALL','CUST_SERVER_SELECT'])"
                  size="mini"
                  type="success"
                  @click="severLookHandle(scope.row)"
                  class="margin-right5"
          >客服查看</el-button>
          <el-dropdown
            size="small"
            v-if="getMoreAllFlag(scope.row)"
          >
            <el-button size="mini" type="success">
              更多操作
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                class="dropdown-item"
                v-if="getEditBtnShow()"
              >
                <edit
                    class="my-button"
                    :data="scope.row"
                    :sup_this="sup_this"
                    :cust-level-list="custLevelList"
                />
              </el-dropdown-item>

              <el-dropdown-item
                class="dropdown-item"
                v-if="getAuditBtnShow()"
              >
                <span @click="to(0)">审核</span>
              </el-dropdown-item>
              <el-dropdown-item
                class="dropdown-item"
                v-if="getTransferBtnShow()"
              >
                <transferBtn
                  class="my-button"
                  :data="scope.row"
                  :sup_this="sup_this"
                />
              </el-dropdown-item>
              <el-dropdown-item
                  class="dropdown-item"
                  v-if="getNotActive(scope.row) && checkPermission(['ADMIN','CUST_ALL','CUST_AUDIT'])"
              >
                <div
                    @click="activeHandle(scope.row)"
                >激活用户</div>
              </el-dropdown-item>
              <el-dropdown-item
                class="dropdown-item"
                v-if="getUpgradeAgentBtnShow(scope.row)"
              >
                <span
                  @click="upgradeHandle(scope.row)"
                >升级代理</span>
              </el-dropdown-item>
              <el-dropdown-item
                class="dropdown-item"
                v-if="getExperienceBtnShow(scope.row)"
              >
                <span @click="openExperienceHandle(scope.row)">体验账户</span>
              </el-dropdown-item>
              <el-dropdown-item
                  class="dropdown-item"
                  v-if="getAccountConfigBtnShow(scope.row)"
              >
                <span @click="accountConfigHandle(scope.row)">开户配置</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <upgrade-dialog
      :dialog-show="dialogShow"
      @close-dialog="dialogShow = false"
      :current-client-info="currentClientInfo"
    ></upgrade-dialog>
    <detail
        ref="detail"
        :sup_this="sup_this"
        :isCheck="true"
        :data="viewData"
        :isShowSubLink="isShowSubLink"
    />
    <el-dialog title="权限验证" :visible.sync="dialogFormVisible" append-to-body width="30%">
      <div class="send-li">
        <li>
          <span class="code-font">手机验证码</span>
        </li>
        <li>
          <el-input placeholder="请输入手机验证码" v-model="smsCode"></el-input>
        </li>
        <li>
          <span v-show="show" @click="getCode" class="count">获取验证码</span>
          <span v-show="!show" class="count">{{count}} s</span>
        </li>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCode">确 定</el-button>
      </div>
    </el-dialog>
    <open-experience-dialog
      :dialog-show="openAccountdialogShow"
      :cust-id="currentCustId"
      @success-done="successDoneHandle"
      @close="closeHandle"
    ></open-experience-dialog>
    <open-account-config-dialog
        ref="configdialog"
      :row-data="currentClientInfo"
      :dialog-show="openAccountConfigDialogShow"
      @close-dialog="closeConfigDialog"
      @success-save="successSave"
    ></open-account-config-dialog>
  </div>
</template>

<script>
  import openAccountConfigDialog from '@/components/customer/cusmgr/openAccountConfigDialog'
  import { mapGetters } from 'vuex';
  import openExperienceDialog from '@/components/public/openExperienceDialog'
  import viewData from '@/mixins/viewData'
  import detail from "@/components/ib/merchantinfo/detail";

import checkPermission from '@/utils/permission'
import initList from '@/mixins/initData'
import { del } from '@/api/cus/tCustInfo'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/customer/cusmgr/header'
import transferBtn from '@/components/customer/cusmgr/transferBtn'
import edit from '@/components/ib/merchantinfo/edit'
import viewDetail from '@/components/ib/merchantinfo/view'
import upgradeDialog from '@/components/customer/cusmgr/upgradeDialog'
import { parseIbStatus,parseCusStatus,parseCustSource } from '@/views/pt/ib/utils/index'
  import { activeUserLink } from '@/api/ib/ibMerchantInfo'
export default {
  name:'customermgr',
  components: {
    eHeader,
    edit,
    viewDetail,
    transferBtn,
    upgradeDialog,
    detail,
    openExperienceDialog,
    openAccountConfigDialog,
  },
  mixins: [initList,viewData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      dialogShow: false,
      currentClientInfo: {},
      currentCustId: 0,
      openAccountdialogShow: false,
      custLevelList: [],
      openAccountConfigDialogShow: false,
    }
  },
  mounted() {
    //代理级别点击代理数量跳转过来
    if(this.$route.query.belongInfo) {
      let belongInfo =this.$route.query.belongInfo;
      this.query = {
        belongInfo:belongInfo
      }
    }
    if(this.cusmgrCustLvl){
      this.query.linkId = this.cusmgrCustLvl;
    }
    this.$nextTick(() => {
      this.init()
    })
  },
  beforeDestroy(){
    this.$store.commit('setCusmgrCustLvl','');
  },
  computed: {
    ...mapGetters(['cusmgrCustLvl']),
  },
  watch:{
    cusmgrCustLvl(newVal){
      this.query.linkId = newVal;
      this.$refs.eheader.query.linkId = newVal;
      this.$refs.eheader.$forceUpdate();
      this.init();
    }
  },
  methods: {
    parseIbStatus,
    parseTime,
    parseCusStatus,
    checkPermission,
    parseCustSource,
    custLvlClearHandle(){
      delete this.query.linkId;
      delete this.$refs.eheader.query.linkId;
      this.$refs.eheader.$forceUpdate();
      this.toQuery();
    },
    beforeInit() {
      this.url = 'crm/tCustInfo'
      const sort = 'applyDate,desc'
      this.params = {
        custTypeStr:'3',
        page: this.page,
        size: this.size,
        sort: sort,
        mt4MainAcc: this.query.mt4MainAcc,
      }
      const query = this.query
      const basicInfo = query.basicInfo
      const auditStatus = query.auditStatus
      const custSource = query.custSource
      const custStatus = query.custStatus
      let belongInfo = query.belongInfo

      const managerInfo = query.managerInfo
      const startTime = this.parseTime(query.startTime)
      const endTime = this.parseTime(query.endTime)


      if(this.$route.query.agencyName){
        this.query.belongInfo = this.$route.query.agencyName;
        this.$route.query.agencyName = null;
      }
      if (managerInfo!=null && managerInfo!='') { this.params['managerInfo'] = managerInfo }
      if (this.query.belongInfo!=null && belongInfo!='') { this.params['belongInfo'] = this.query.belongInfo }
      if(auditStatus!=null && auditStatus!=''){ this.params['auditStatus'] = auditStatus }
      if (custSource!=null && custSource!='') { this.params['custSource'] = custSource }
      if (custStatus!=null && custStatus!='') { this.params['custStatus'] = custStatus }
      if (basicInfo!=null && basicInfo!='') { this.params['basicInfo'] = basicInfo }
      if (startTime!=null && startTime!='') { this.params['startTime'] = startTime }
      if (endTime!=null && endTime!='') { this.params['endTime'] = endTime }
      if(this.query.startAuditTime){
        this.params.startAuditTime = this.parseTime(this.query.startAuditTime);
      }
      if(this.query.endAuditTime){
        this.params.endAuditTime = this.parseTime(this.query.endAuditTime);
      }
      if(this.query.linkId){
        this.params.linkId = this.query.linkId;
      }
      return true
    },

    successSave(){
      this.init();
      this.openAccountConfigDialogShow = false;
    },
    getAccountConfigBtnShow(row){
      // 配置权限 todo
      return this.checkPermission(['ADMIN','OPEN_TRIAL_ACCOUNT']);
    },
    getCustomerLevel(row){
      return row.levelName || '-';
    },
    subDelete(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    },
    upgradeHandle(row){
      this.dialogShow = true;
      this.currentClientInfo =  row
    },
    getNotActive(row){
      return row.custStatus == 10;
    },
    activeHandle(row){
      this.$confirm(`确认激活该用户`,'提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        //点击确定的操作(调用接口)
        activeUserLink(row.id).then((res) => {
          this.$message({
            type: 'success',
            message: '激活成功',
          })
          this.init();
        })
      }).catch(() => {
        //几点取消的提示
      });
    },
    getExperienceBtnShow(row){
      return row.auditStatus == 4 && !row.hasTrial &&  this.checkPermission(['ADMIN','OPEN_TRIAL_ACCOUNT'])
    },
    openExperienceHandle(row){
      this.openAccountdialogShow = true;
      this.currentCustId = row.id;
    },
    successDoneHandle(){
      this.init();
    },
    closeHandle(){
      this.openAccountdialogShow = false;
    },
    getUpgradeAgentBtnShow(row){
      return row.auditStatus == 4 && row.custType == 3 && this.checkPermission(['ADMIN','CUST_ALL','CUST_NOTICE'])
    },
    getAuditTime(auditTime){
      if(auditTime){
        return parseTime(auditTime);
      }else {
        return '-'
      }
    },
    clear(){
      this.query = {};
      this.toQuery();
    },
    getEditBtnShow(){
      return checkPermission(['ADMIN','CUST_ALL','CUST_EDIT'])
    },
    getAuditBtnShow(){
      return checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_AUDIT','CUST_ALL','CUST_AUDIT'])
    },
    getTransferBtnShow(){
      return checkPermission(['ADMIN','CUST_ALL','CUST_CHANGE'])
    },
    getMoreAllFlag(row){
      return this.getEditBtnShow() || this.getAuditBtnShow() || this.getTransferBtnShow() || this.getUpgradeAgentBtnShow(row) || this.getExperienceBtnShow(row);
    },

    accountConfigHandle(row){
      this.currentClientInfo = row;
      this.openAccountConfigDialogShow = true;
      this.$refs.configdialog.resetForm();
    },

    closeConfigDialog(){
      this.openAccountConfigDialogShow = false;
    }
  }
}
</script>

<style scoped>
  .button-div {
    display: inline-block;
    margin-right: 3px;
  }

</style>
